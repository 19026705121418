<template>
<div id="app" class="app">

  <!-- 
    pc的第一个界面 
  -->
  <div class="pc1" v-if="type === 0 && !isMobile">
    <div class="pc1flex">
      <div class="left">
        <div class="text1" :style="{ fontSize: autoSize(52), lineHeight: autoSize(104), }">
          专业人才培养方案动态应用与服务平台
        </div>
        <div class="text2" :style="{ fontSize: autoSize(32), lineHeight: autoSize(64), }">
          ・为每一位教师打造专属AI图谱工作台，教与学新形态
        </div>
        <div class="left-3">
          <span class="text3" v-for="(item, index) in list" :key="index"
            :style="{ width: autoSize(133), height: autoSize(40), fontSize: autoSize(16), lineHeight: autoSize(40), }">{{
    item }}</span>
        </div>
      </div>
      <img src="../assets/App/homebg.png" class="img-bg" :style="{ width: autoSize(880) }" />
    </div>
    <img src="../assets/App/homebg2.png" class="pc1-img-bottom" :style="{ width: autoSize(450), height: autoSize(450) }" />
    <img src="../assets/App/hometitle.png" class="img-title" />
    <div class="use" @click="type = 1;">申请试用</div>
    <vue-qr class="qr" :text="qrcodeData" :size="160" />
    <div class="qrtext">扫码申请试用</div>
    <div class="bottom"> <span @click="icp">湘ICP备 18024560号-1</span> <span @click="gateway">湘公网安备 43019002001604号</span>
      <span>技术支持：北京贝湾教育科技有限公司</span>
    </div>
  </div>

    <!-- 
    pc的第2个界面 
  -->
  <div class="pc2" v-if="type === 1 && !isMobile">
      <div class="pc2left">
        <img src="../assets/App/homebg3.png" ref="image" class="pc2leftimg1" />
        <img src="../assets/App/homebg.png" ref="image" class="pc2leftimg2" />

        <div class="bottom" ref="bottom"> <span @click="icp">湘ICP备 18024560号-1</span> <span  @click="gateway">湘公网安备 43019002001604号</span>
          <span>技术支持：北京贝湾教育科技有限公司</span>
        </div>
      </div>

      <div class="pc2right">
        <div class="pc2rightbg">
          <div class="pc2rightbg-title">
            <span @click="backTitleClick">光谱计划</span>
          </div>
          <el-form :model="form" label-width="471px" :rules="rules" ref="formpc2">
            <el-form-item label="" prop="phone">

              <div class="box"> 
                <el-input v-model="form.phone" placeholder="请输入手机号"   type="tel"
              maxlength="11" 
             >
                  <template #prefix>
                    <div class="input-left"><img width="30" height="30" src="../assets/App/home1.png" /></div>
                  </template>
                </el-input>

              </div>

            </el-form-item>
            <el-form-item label="" prop="code">
              <div class="box"> <el-input v-model="form.code" placeholder="请输入验证码"   type="tel"
        
             >
                  <template #prefix>
                    <div class="input-left"><img width="30" height="30" src="../assets/App/home2.png" /></div>
                  </template>
                </el-input>
                <div class="ma" @click="getCode" :disabled="isCounting"> {{ buttonText() }}</div>
              </div>
            </el-form-item>
            <el-form-item label="" prop="name">
              <div class="box"> <el-input v-model="form.name" placeholder="请输入单位/院校名称" maxlength="32">
                  <template #prefix>
                    <div class="input-left"><img width="30" height="30" src="../assets/App/home3.png" /></div>
                  </template>
                </el-input></div>
            </el-form-item>
          </el-form>
          <div class="box">   <el-input v-model="form.code2" placeholder="请输入邀请码（选填）">
            <template #prefix>
              <div class="input-left"><img width="30" height="30" src="../assets/App/home4.png" /></div>
            </template>
          </el-input></div>
          <div class="submit" @click="submitpc" :disabled="!isFormValid"
            :style="{ 'background-color': submitback(), color: submittext() }">申请试用</div>
        </div>
    </div>

  </div>

    <!-- 
   手机的第一个界面 
  -->
  <div class="phone1" v-if="type === 0 && isMobile">
    <div class="phone1-title">光谱计划</div>

    <div class="phone1-imgbg"> <img class="phone1-img" src="../assets/App/homebg4.png" alt=""></div>

    <div class="phone1-title">专业人才培养方案动态应用与服务平台</div>
    <div class="phone1-des">为每一位教师打造专属AI图谱工作台，教与学新形态</div>

    <div class="phone1-left-3">
      <span class="phone1-text3" v-for="(item, index) in list2" :key="index">{{ item }}</span>
    </div>
    <div class="phone1-left-3">
      <span class="phone1-text3" v-for="(item, index) in list3" :key="index">{{ item }}</span>
    </div>
    <div class="phone1-bottom">
      <span class="phone1-use" @click="type = 1;">申请试用</span>
    </div>
  </div>


   <!-- 
   手机的第2个界面 
  -->
  <div class="phone1" v-if="type === 1 && isMobile">
    <div class="phone1-title">
      <span @click="backTitleClick">光谱计划</span>
    </div>
    <div class="phone2-imgbg">
      <img class="phone2-img" src="../assets/App/homebg4.png" alt="">
    </div>
    <div class="phone2-right">
      <el-form :model="form" label-width="471px" :rules="rules" ref="formpc2">
        <el-form-item label="" prop="phone">

          <div class="phone2-box"> 
            <el-input v-model="form.phone" placeholder="请输入手机号"   type="tel"
              maxlength="11" 
             >
              <template #prefix>
                <div class="phone2-input-left"><img width="30" height="30" src="../assets/App/home1.png" /></div>
              </template>
            </el-input>

          </div>

        </el-form-item>
        <el-form-item label="" prop="code">
          <div class="phone2-box">  <el-input v-model="form.code" placeholder="请输入验证码"   type="tel"
        
        >
              <template #prefix>
                <div class="phone2-input-left"><img width="30" height="30" src="../assets/App/home2.png" /></div>
              </template>
            </el-input>
            <div class="phone2-ma" @click="getCode" :disabled="isCounting"> {{ buttonText() }}</div>
          </div>
        </el-form-item>
        <el-form-item label="" prop="name">
          <div class="phone2-box"> <el-input v-model="form.name" placeholder="请输入单位/院校名称" maxlength="32" class="phone2-input">
              <template #prefix>
                <div class="phone2-input-left"><img width="30" height="30" src="../assets/App/home3.png" /></div>
              </template>
            </el-input></div>
        </el-form-item>
      </el-form>
      <div class="phone2-box">   <el-input v-model="form.code2" placeholder="请输入邀请码（选填）" class="phone2-input2">
        <template #prefix>
          <div class="phone2-input-left"><img width="30" height="30" src="../assets/App/home4.png" /></div>
        </template>
      </el-input></div>
      <div class="phone2-submit" @click="submitpc" :disabled="!isFormValid"
        :style="{ 'background-color': submitback(), color: submittext() }">申请试用</div>
    </div>
  </div>
</div>
</template>
<script>

var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?8b3283e95183f435059560be6e6f5a67";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();


import { sendUse, getPhoneCode, cheakCode } from "@/api/index";
import VueQr from 'vue-qr';
export default {
  components: {
    VueQr,
  },
  data() {
     
    return {
      submitting: false,
      isMobile: false,
      type: 0,
      countdown: 60,
      isCounting: false,
      timer:null,
      isPhone: false,
      qrcodeData: 'https://spectrum.lumibayedu.com',
      list: ['知识图谱', '专业图谱', '课程图谱', '能力图谱', '问题图谱',],
      list2: ['知识图谱', '专业图谱', '课程图谱',],
      list3: ['能力图谱', '问题图谱',],
      form: {
        phone: '', code: '', name: '', code2: '',
      },
      rules: {
        phone: [
          { required: true, message: '手机号不可为空', trigger: 'blur' },
          { validator: this.validatePhoneNumber, trigger: 'blur' },
        ],
        code: [
          { required: true, message: '验证码不可为空', trigger: 'blur' },
          { validator: this.validateCode, trigger: 'blur' },
        ],
        name: [
          { required: true, message: '单位/院校名称不可为空', trigger: 'blur' },
          { min: 4, message: '单位/院校名称至少为4个字符', trigger: 'blur' },
        ],
      },
    };
  },
  mounted() {
    this.updateDeviceType();
    window.addEventListener('resize', this.detectMobileDevice);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectMobileDevice);
  },
  watch: {
        'form.phone'(newValue) {
          console.log(newValue)
          newValue=newValue.replace(/\D/g, '')
          console.log(newValue)
          this.form.phone = newValue.substring(0, 11);

        },
        'form.code'(newValue) {
          console.log(newValue)
          this.form.code = newValue.replace(/\D/g, '')

        },
      },
  methods: {
   
    autoSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if (!clientWidth) return
      const designWidth = 1920
      const fonsize = clientWidth / designWidth
      return res * fonsize + 'px' // 确保返回一个带有单位的字符串
    },
    validatePhoneNumber(rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号码不可为空'));
        this.isPhone = false
      } else {
        const reg = /^1[3-9]\d{9}$/;
        if (reg.test(value)) {
          this.isPhone = true
          callback();
        } else {
          this.isPhone = false
          callback(new Error('请输入有效的11位手机号码'));
        }
      }
    },
    detectMobileDevice() {
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      const mobileBrowser = mobileAgents.some(vendor => userAgentInfo.indexOf(vendor) > 0);
      this.isMobile = mobileBrowser && window.innerWidth <= 991;
      console.log('this.isMobile', this.isMobile);
    },
    updateDeviceType() {
      this.isMobile = window.innerWidth < 768;
      console.log(' this.isMobile', this.isMobile)
    },
    backTitleClick () {
      this.type = 0
    },
    icp() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    },
    gateway () {
      window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001604')
    },
    buttonText() {
      return this.isCounting ? `${this.countdown}s` : '获取验证码';
    },
    validateCode(rule, value, callback) {
      console.log('phone',this.form.phone)
      if (value === '') {
        callback(new Error('验证码不可为空'));
      } else {
if(this.form.phone){
  cheakCode({ mobile: this.form.phone, code: value }).then((res) => {
          const { success, message } = res.data
          console.log('message', message)
          if (success) {
            callback();
          } else {
            callback(new Error(message));
          }
        })
}else{
  callback();
}
      

      }
    },
    async getCode() {
      this.$refs.formpc2.validateField('phone', (valid) => {
        console.log('valid', valid)
        if (!valid) {
          this.getSuccessCode()
        }
      })
    },
    async getSuccessCode () {
      if (!this.isPhone) {
          this.$message({
            showClose: true,
            message: "请输入正确手机号",
            type: "error",
          });
          return;
        }
        if (!this.isCounting) {
          this.isCounting = true;

          const res = await getPhoneCode({ mobile: this.form.phone });
          const { success, message } = res.data;
          console.log('this.success', success)
          if (success) {
          this. timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
              } else {
                this.stopTime()
              }
            }, 1000);
            this.$message({
              showClose: true,
              message: '发送成功',
              type: "success",
            });
          } else {
            this.isCounting = false;
            this.$message({
              showClose: true,
              message: message,
              type: "error",
            });
          }
        }
    },
    isFormValid() {
      const { phone, code, name } = this.form; // 假设你只想检查这三个输入项
      const nonEmptyCount = [phone, code, name].filter(input => input.trim() !== '').length;
      return nonEmptyCount === 3;
    },
    submitback() {
      console.log(' this.submitback', this.isFormValid)
      return this.isFormValid() ? '#333333' : '#F1F1F6';
    },
    submittext() {
      return this.isFormValid() ? '#ffffff' : '#C8C9DC';
    },
    submitpc() {
      this.$refs.formpc2.validate((valid) => {
        if (valid) {
          this.send();
        } else {
          return false;
        }
      });
    },
    stopTime(){
      clearInterval(this.timer);
          this.isCounting = false;
          this.countdown = 60;
    },
    async send() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      const res = await sendUse({ mobile: this.form.phone, code: this.form.code, name: this.form.name, invitationCode: this.form.code2, source: this.isMobile ? 2 : 1, });
      const { success, message } = res.data;
      if (success) {
        this.submitting = false; 
        this.form = {
            phone: '', code: '', name: '', code2: '',
          }
        this.$message({
          showClose: true,
          message: "申请成功，我们将会稍后联系您",
          type: "success",
        });
        setTimeout(function () {
          console.log('setTimeout')
         
          this.type = 0
       this.stopTime()
        }.bind(this), 5000);
      } else {
        this.submitting = false; 
        this.$message({
          showClose: true,
          message: message,
          type: "error",
        });
      }

    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}



html,
body {
  height: 100%;
  width: 100%;
  /* overflow-y: hidden; */
  /* 或者使用 overflow: hidden; */
}

/* .app{
  width: 100vw;
  height: 100vw;
}
.pc1{
  width: 100%;
  height: 100vw;
} */
.pc1 {
  position: relative;

  .pc1-img-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .img-title {
    width: 483px;
    height: 54px;
    object-fit: cover;
    position: absolute;
    left: 60px;
    top: 40px;
  }

  .use {
    width: 153px;
    position: absolute;
    right: 60px;
    top: 46px;
    height: 42px;
    background: #1C1C29;
    border-radius: 6px 6px 6px 6px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 42px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    text-align: center;
  }

  .pc1flex {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;

    .left {
      width: 50%;
      margin-left: 75px;

      .text1 {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #000000;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .text2 {
        font-family: PingFang SC, PingFang SC;
        font-weight: 300;
        color: #000000;
        text-align: left;
        font-style: normal;
        margin-top: 13px;
        text-transform: none;
      }

      .left-3 {
        display: flex;
        gap: 30px;
        margin-top: 40px;

        .text3 {
          background-image: url(../assets/App/homeicon.png);
          background-size: cover;
          /* 调整背景图片大小 */
          background-position: center;
          /* 设置图片位置，使其靠左 */
          font-family: Inter, Inter;
          font-weight: 400;
          color: #FFFFFF;
          text-align: left;
          font-style: normal;
          text-transform: none;
          text-align: center;
          padding-left: 20px;
        }
      }
    }

    .img-bg {
      width: 50%;
      object-fit: cover;
    }
  }


  .qr {
    position: absolute;
    bottom: 100px;
    right: 60px;
  }

  .qrtext {
    position: absolute;
    bottom: 60px;
    right: 92px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 32px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .bottom {
    position: absolute;
    bottom: 24px;
   
    display: flex;
    gap: 24px;
     left: 50%;
    transform: translateX(-50%);

    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }

}

.pc2 {
  background-color: #F6F7FB;

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;


    .pc2left {
    width: 50%;
    height: 100%;
    background-color: #FFFFFF;
    position: relative;

    .pc2leftimg1 {
      position: absolute;
      width: 400px;
      top: 0;
      left: 0;
    }

    .pc2leftimg2 {
      position: absolute;
      width: 85%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .bottom {
      position: absolute;
      bottom: 24px;
      display: flex;
      width: 100%;
     justify-content: center;
      span {
margin-left: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 14px;
      }
    }
  }

  .pc2right {
    width: 50%;
display: flex;align-items: center;
justify-content: center;
    .pc2rightbg {
      height: 618px;
      width: 80%;
      max-width: 600px;
      background: #FFFFFF;
      border-radius: 30px 30px 30px 30px;
      /* top: 50%;
      transform: translate(-50%,-50%); */
      padding-left: 66px;
      padding-right: 66px;
      padding-top: 56px;
      
      >div {
        width: 100%;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #333333;
        line-height: 30px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }

      .box {
        display: flex;
        margin-top: 24px;
        justify-content: space-between;

        .ma {
          margin-left: 20px;
          width: 140px;
          height: 62px;
          text-align: center;
          background: #F6F7FF;
          border-radius: 6px 6px 6px 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #5158FF;
          line-height: 62px;
          font-style: normal;
          text-transform: none;
        }
      }


      .el-input {
        width: 100%;
        flex-grow: 1;
        height: 62px;
        max-lines: 32;
      }

      .input-left {

        height: 62px;
        display: flex;
        align-items: center;
        width: 50px;
        padding-left: 14px;
      }

      .el-input__inner {
        height: 62px;
        padding-left: 50px;
        width: 100%;
        background-color: #F6F7FF;
        /* 设置输入框的背景颜色 */
        color: #333333;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        border-color: #F6F7FF;
        /* 设置输入框边框的颜色 */
        border-radius: 6px;
      }

      .el-input__inner:focus {
        border-color: #F6F7FF;
        /* 设置输入框聚焦时的边框颜色 */
      }

      .el-input__inner:hover {
        border-color: #c0ccda;
        /* 设置鼠标悬停时输入框边框的颜色 */
      }

      .submit {
        margin-top: 33px;
        width: 100%;
        height: 62px;
        background: #F1F1F6;
        border-radius: 10px 10px 10px 10px;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #C8C9DC;
        line-height: 62px;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}

.phone1 {
  background-color: #ffffff;
  width: 100%;

}
.phone1-title {
    width: 100%;
    height: 44px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 44px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top: 10px;
  }

  .phone1-imgbg {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

   
  }
  .phone1-img {
      max-width: 100%; /* 限制图片的最大宽度为容器的100% */
  height: auto;
    }
  .phone1-des {
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 16px;
    color: #000000;
    line-height: 32px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .phone1-left-3 {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    justify-content: center;
  }
  .phone1-text3 {
      width: 97px;
      height: 30px;
      background-image: url(../assets/App/homeicon.png);
      background-size: cover;
      /* 调整背景图片大小 */
      background-position: center;
      /* 设置图片位置，使其靠左 */
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 30px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      text-align: center;
      padding-left: 20px;
    }
  .phone1-bottom {
    display: flex;
    justify-content: center;
    margin-top: 50px;

   
  }
  .phone1-use {
      width: 256px;
      height: 50px;
      background: #1C1C29;
      border-radius: 10px 10px 10px 10px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 50px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .phone2-imgbg {
    display: flex;
    justify-content: center;

   
  }
  .phone2-img {
      width: 267px;
      height: 201px;
    }

    .phone2-right {
    width: 100%;
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;

  }

  /* .phone2-div {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 26px;
      color: #333333;
      line-height: 30px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    } */

    .phone2-box {
      display: flex;
      margin-top: 24px;
      justify-content: space-between;
      height: 62px;
      
    }
    .phone2-ma {
        margin-left: 20px;
        width: 140px;
        height: 62px;
        text-align: center;
        background: #F6F7FF;
        border-radius: 6px 6px 6px 6px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #5158FF;
        line-height: 62px;
        font-style: normal;
        text-transform: none;
      }
      .el-input {
      width: 100%;
      flex-grow: 1;
      height: 62px;

    }

    .phone2-input-left {

      height: 62px;
      display: flex;
      align-items: center;
      width: 50px;
      padding-left: 14px;
    }
    
    /deep/.el-input__inner {
      height: 62px !important;
      padding-left: 50px;
      width: 100%;
      background-color: #F6F7FF;
      color: #333333;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      border-color: #F6F7FF;
      border-radius: 6px;
    }

  .el-input__inner:focus {
      border-color: #F6F7FF;
      /* 设置输入框聚焦时的边框颜色 */
    }

   .el-input__inner:hover {
      border-color: #c0ccda;
      /* 设置鼠标悬停时输入框边框的颜色 */
    }

    .phone2-submit {
      margin-top: 33px;
      width: 100%;
      height: 62px;
      background: #F1F1F6;
      border-radius: 10px 10px 10px 10px;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #C8C9DC;
      line-height: 62px;
      font-style: normal;
      text-transform: none;
    }


</style>
